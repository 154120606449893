import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import _ from "lodash";
import styled from "styled-components";
import { navigate } from "@reach/router";
import Collapse from "@material-ui/core/Collapse";
import useSettings from "../../../hooks/useSettings";
import labels from "../../../helpers/labels.json";
import SystemService from "../../../services/SystemService";

const drawerWidth = 273;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  drawerContainer: {
    overflow: "auto",
    backgroundColor: "#293461",
    height: "100%",
  },
  inactiveListItem: {
    padding: "0px",
    paddingLeft: 42,
    height: 62,
    borderBottom: "solid 0.3px rgba(255, 255, 255, 0.12)",
    "& span": {
      transition: "250ms",
    },
    "&:hover": {
      backgroundColor: "#333d68",
      "& span": {
        color: "#fff",
      },
    },
  },
  activeListItem: {
    padding: "0px",
    paddingLeft: 42,
    height: 62,
    borderBottom: "solid 0.3px rgba(255, 255, 255, 0.12)",
    "& span": {
      transition: "250ms",
    },
    "&:hover": {
      backgroundColor: "#333d68",
      "& span": {
        color: "#fff",
      },
    },
  },
  nested: {
    margin: "0 0 15px 0",
    marginLeft: "50px",
    width: "200px !important",
    height: "38px",
    borderRadius: "5px",
    transition: "300ms",
    boxSizing: "borderbox",
    "& span": {
      transition: "250ms",
      fontSize: "14px",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      borderLeft: "3px solid #fff",
      // borderRadius: '0 5px 5px 0'
      "& span": {
        color: "#fff",
      },
    },
  },
  inactiveNested: {
    backgroundColor: "transparent",
  },
  activeNested: {
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    borderLeft: "3px solid #fff",
    // borderRadius: '0 5px 5px 0'
  },
}));

// const handleRoute = (route) => {
//   switch (route) {
//     case "home":
//       navigate("/dashboard");
//       break;
//     case "my_cases":
//       navigate("/dashboard/cases");
//       break;
//     case "my_parties":
//       navigate("/dashboard/verify-parties");
//       break;
//     case "my_profile":
//       navigate("/dashboard/profile");
//       break;
//     case "payments":
//       navigate("/dashboard/payments");
//       break;
//     case "help":
//       navigate("/dashboard/help");
//       break;
//     default:
//       break;
//   }
// };

export default function Sidebar({
  selectedItem,
  selectedSubItem,
  showDrawer,
  setShowDrawer,
}) {
  const classes = useStyles();
  const { setting, setSetting, setCaseLifeCycleStatus } = useSettings();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(false);
  };

  const sidebar_options = [
    {
      icon: require("../../../assets/images/hamMenu.svg"),
      inactive: require("../../../assets/images/hamMenuInactive.svg"),
      title: labels.my_tasks,
      path: "/dashboard",
      listItems: [
        {
          icon: require("../../../assets/images/pendingInactive.svg"),
          inactive: require("../../../assets/images/pending.svg"),
          title: labels.pending_actions,
          path: "/dashboard",
        },
        {
          icon: require("../../../assets/images/invoice.svg"),
          inactive: require("../../../assets/images/invoiceInactive.svg"),
          title: labels.proforma_invoice,
          path: "/dashboard/proforma-invoice",
        },
        {
          icon: require("../../../assets/images/upload.svg"),
          inactive: require("../../../assets/images/uploadInactive.svg"),
          title: labels.upload,
          path: "/dashboard/uploads",
        },
        {
          icon: require("../../../assets/images/tick.svg"),
          inactive: require("../../../assets/images/tickInactive.svg"),
          title: labels.verify,
          path: "/dashboard/verify-parties",
          hide: setting?.partyVerificationMethod === "automatic" ? true : false,
        },
        {
          icon: require("../../../assets/images/upload.svg"),
          inactive: require("../../../assets/images/uploadInactive.svg"),
          title: "Bulk Notice",
          path: "/dashboard/bulk-notice",
        },
      ],
    },
    {
      icon: require("../../../assets/images/cases.svg"),
      inactive: require("../../../assets/images/casesInactive.svg"),
      title: labels.cases,
      path: "/dashboard/negotiation",
      listItems: [
        {
          icon: require("../../../assets/images/negotiation-active.svg"),
          inactive: require("../../../assets/images/negotiation-inactive.svg"),
          title: labels.negotiation,
          path: "/dashboard/negotiation",
        },
        {
          icon: require("../../../assets/images/mediation-active.svg"),
          inactive: require("../../../assets/images/mediation-inactive.svg"),
          title: labels.mediation,
          path: "/dashboard/mediation",
        },
        {
          icon: require("../../../assets/images/mediation-active.svg"),
          inactive: require("../../../assets/images/mediation-inactive.svg"),
          title: labels.arbitration,
          path: "/dashboard/arbitration",
        },
        {
          icon: require("../../../assets/images/bulkUploads.svg"),
          inactive: require("../../../assets/images/bulkUploadsInactive.png"),
          title: labels.bulk_uploads,
          path: "/dashboard/bulk-uploads",
        },
      ],
    },
    {
      icon: require("../../../assets/images/my_parties.svg"),
      inactive: require("../../../assets/images/partiesInactive.svg"),
      title: labels.parties,
      path: "/dashboard/parties",
    },
    {
      icon: require("../../../assets/images/profiles.svg"),
      inactive: require("../../../assets/images/profilesInactive.svg"),
      title: labels.profiles,
      path: "/dashboard/profiles",
    },
    {
      icon: require("../../../assets/images/payments.svg"),
      inactive: require("../../../assets/images/paymentsInactive.svg"),
      blueIcon: require("../../../assets/images/Payment History_active_Blue.svg"),
      title: labels.payments,
      path: "/dashboard/payments",
    },
    {
      icon: require("../../../assets/images/cases.svg"),
      inactive: require("../../../assets/images/casesInactive.svg"),
      title: labels.meetings,
      path: "/dashboard/generic-meeting",
      listItems: [
        {
          icon: require("../../../assets/images/mediation-active.svg"),
          inactive: require("../../../assets/images/mediation-inactive.svg"),
          title: labels.generic_meeting,
          path: "/dashboard/generic-meeting",
        },
        {
          icon: require("../../../assets/images/mediation-active.svg"),
          inactive: require("../../../assets/images/mediation-inactive.svg"),
          title: labels.meeting_details,
          path: "/dashboard/meeting-details",
        },
        {
          icon: require("../../../assets/images/cases.svg"),
          inactive: require("../../../assets/images/casesInactive.svg"),
          title: labels.daily_casuse,
          path: "/dashboard/daily-cause",
        },
      ],
    },
    {
      icon: require("../../../assets/images/userPlaceholder.svg"),
      inactive: require("../../../assets/images/userPlaceholder.svg"),
      blueIcon: require("../../../assets/images/userPlaceholder.svg"),
      title: labels.transcriber,
      path: "/dashboard/transcriber",
    },
    {
      icon: require("../../../assets/images/home.svg"),
      inactive: require("../../../assets/images/homeInactive.svg"),
      blueIcon: require("../../../assets/images/Home_active_Blue.svg"),
      title: labels.mis_dashboard,
      path: "/dashboard/mis-dashboard",
    },
    {
      icon: require("../../../assets/images/home.svg"),
      inactive: require("../../../assets/images/homeInactive.svg"),
      blueIcon: require("../../../assets/images/userPlaceholder.svg"),
      title: labels.management_tools,
      path: "/dashboard/tools",
    },
  ];

  useEffect(() => {
    async function getSystemConfig() {
      try {
        const response = await SystemService.getConfigAuth();
        if (response.systemConfig) {
          setSetting(response.systemConfig);
        }
        setCaseLifeCycleStatus(response?.caseLifeCycle);
      } catch (error) {
        throw error;
      }
    }
    getSystemConfig(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Collapse
      in={!showDrawer}
      open={!showDrawer}
      timeout="auto"
      unmountOnExit
      className={classes.wrapperInner}
    >
      <Drawer
        open={!showDrawer}
        anchor={"left"}
        className={classes.drawer}
        onClose={toggleDrawer(false)}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />

        <div className={classes.drawerContainer}>
          <List>
            {sidebar_options.map((data, index) => (
              <React.Fragment key={index}>
                <ListItem
                  className={
                    data.title === selectedItem
                      ? classes.activeListItem
                      : classes.inactiveListItem
                  }
                  style={
                    data.hasOwnProperty("listItems") ? { borderBottom: 0 } : {}
                  }
                  button
                  onClick={() => navigate(data.path)}
                  key={data.title}
                >
                  <ListItemIcon>
                    <Icon
                      src={
                        data.title === selectedItem ? data.icon : data.inactive
                      }
                    />
                  </ListItemIcon>
                  <ListItemText selected={data.title === selectedItem}>
                    {_.startCase(data.title)}
                  </ListItemText>
                </ListItem>
                {data.hasOwnProperty("listItems") && (
                  <Collapse in={true} timeout="auto" unmountOnExit>
                    <List
                      style={{
                        borderBottom: `solid 0.3px rgba(255, 255, 255, 0.12)`,
                      }}
                      component="div"
                      disablePadding
                    >
                      {data.listItems.map((li, index) =>
                        li.hide ? null : (
                          <ListItem
                            key={index}
                            button
                            className={`${classes.nested} ${
                              li.title === selectedSubItem
                                ? classes.activeNested
                                : classes.inactiveNested
                            }`}
                            onClick={() => navigate(li.path)}
                          >
                            <ListItemIcon>
                              <Icon
                                src={
                                  li.title === selectedSubItem
                                    ? li.icon
                                    : li.inactive
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              selected={li.title === selectedSubItem}
                            >
                              {_.startCase(li.title)}
                            </ListItemText>
                          </ListItem>
                        ),
                      )}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </div>
      </Drawer>
    </Collapse>
  );
}

const ListItemText = styled.span`
  font-family: openSans-bold, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ selected }) => (selected ? "#ffffff" : "#acb1c2")};
`;

const Icon = styled.img`
  object-fit: contain;
  width: 16px;
  height: 16px;
  color: #acb1c2;
`;

const ListItemIcon = styled.div`
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
