import React, { useState } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import theme from "../../../assets/theme";
import FormField from "../../common/FormField/FormField";
import { Formik } from "formik";
import { CustomFileUpload } from "../../common/FormInputs";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import COLORS from "../../../assets/Colors";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import * as XLSX from "xlsx";
import * as PDFJS from "pdfjs-dist";
import JSZip from "jszip";
import AddressLabel from "../../Mis/component/Label";
import { navigate } from "@reach/router";
import { renameValidationSchema } from "../../../helpers/validationSchema";

const Rename = () => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [addressLabel, setAddressLabel] = useState(false);

  async function s3Upload(
    file,
    field,
    setFieldValue,
    fileTypes,
    accept,
    values,
    fileName,
  ) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file ${file.name}` });
        // const errorMessage = "Invalid File Type";
        // if (!fileTypes.includes(file?.type)) throw errorMessage;
        if (accept === ".xlsx") {
          const response = await readExcelFile(file);
          setFieldValue(field, response);
          setFieldValue(fileName, file.name);
        } else if (accept === ".zip") {
          setFieldValue(field, file);
          setFieldValue(fileName, file.name);
        }
      } catch (err) {
        enqueueSnackbar(err, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  const inputs = [
    {
      name: "preInputText",
      type: "text",
      label: "Pre Name",
    },
  ];

  const inputs_date = [
    {
      name: "postInputText",
      type: "text",
      label: "Post Name",
    },
  ];

  const upload_templates = [
    {
      name: "excelFile",
      label: "Upload Xlsx",
      type: "upload",
      required: true,
      fileName: "excel",
      accept: ".xlsx",
      types: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
      ],
    },
    {
      name: "zipFilePath",
      label: "Zip File (zip)",
      type: "upload",
      required: true,
      fileName: "zip",
      accept: ".zip",
      types: ["application/zip"],
    },
  ];

  async function onFormSubmit(values, { resetForm }) {
    try {
      const response = await processZipFile(
        values?.excelFile,
        values?.zipFilePath,
        values?.preInputText,
        values?.postInputText,
      );
      const fileName = values?.zip.replace(/\.zip$/, "");
      const url = URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}_Output.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      resetForm();
    } catch (error) {
    } finally {
    }
  }

  /**
   * @description Read xlsx file
   * @param {*} file
   * @returns
   */

  const readExcelFile = async (file) => {
    const arrayBuffer = await file.arrayBuffer(); // Convert file to ArrayBuffer
    const workbook = XLSX.read(arrayBuffer, { type: "array" }); // Read the workbook
    const sheetName = workbook.SheetNames[0]; // Get the first sheet
    const sheet = workbook.Sheets[sheetName]; // Access the first sheet
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert to 2D array

    const headerRow = data[0]; // First row is the header
    const contractNumberIndex = headerRow.indexOf("CONTRACT NUMBER");

    if (contractNumberIndex === -1) {
      throw new Error("CONTRACT NUMBER column not found");
    }

    // Extract values under "CONTRACT NUMBER" column
    const contractNumbers = data
      .slice(1)
      .map((row) => row[contractNumberIndex])
      .filter(Boolean);

    return contractNumbers;
  };

  /**
   * @description unzip file
   * @param {*} ids
   * @param {*} zipFile
   * @returns
   */

  const processZipFile = async (
    contarcatNumbers,
    zipFile,
    preInputText,
    postInputText,
  ) => {
    if (!zipFile || contarcatNumbers.length === 0) {
      enqueueSnackbar("Please upload a ZIP file and enter Contract Numbers.", {
        variant: "error",
      });
      return;
    }

    try {
      const zip = new JSZip();
      const loadedZip = await zip.loadAsync(zipFile); // Load the ZIP file
      const outputZip = new JSZip();

      for (const [filename, fileData] of Object.entries(loadedZip.files)) {
        if (filename.endsWith(".pdf")) {
          const pdfData = await fileData.async("arraybuffer");
          const pdfText = await extractTextFromPDF(pdfData);

          const matchedContractNumber = contarcatNumbers.find((number) =>
            pdfText.includes(number),
          );
          const newFilename = matchedContractNumber
            ? `${preInputText}${matchedContractNumber}${postInputText}.pdf`
            : `${filename}.pdf`;

          outputZip.file(newFilename, pdfData); // Save with new name
        }
      }

      const zipBlob = await outputZip.generateAsync({ type: "blob" });
      return zipBlob;
    } catch (error) {
      enqueueSnackbar("Error processing ZIP file:", {
        variant: "error",
      });
    }
  };

  /**
   * @description Extarct text from pdf
   * @param {*} pdfData
   * @returns
   */

  const extractTextFromPDF = async (pdfData) => {
    const pdfDocument = await PDFJS.getDocument({ data: pdfData }).promise;
    let fullText = "";

    for (let i = 0; i < pdfDocument.numPages; i++) {
      const page = await pdfDocument.getPage(i + 1);
      const textContent = await page.getTextContent();
      fullText += textContent.items.map((item) => item.str).join(" ");
    }

    return fullText;
  };

  const actions = [
    {
      text: "Address label",
      props: {
        onClick: () => setAddressLabel(true),
      },
    },
  ];

  return (
    <>
      <ActionBar breadcrumbs={["Management Tools"]} {...{ actions }} />
      <Container>
        <CreateContainer>
          <Formik
            initialValues={{
              preInputText: "",
              postInputText: "",
              excelFile: "",
              zipFilePath: "",
              excel: "",
              zip: "",
            }}
            validationSchema={renameValidationSchema}
            enableReinitialize
            onSubmit={onFormSubmit}
            validateOnBlur
            validateOnChange
          >
            {({
              values,
              handleChange,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
              resetForm,
            }) => (
              <StyledForm>
                <StyledFormElementsWrapper
                  style={{ marginBottom: 8, marginTop: 15 }}
                >
                  <Grid className="mis-dashboard">
                    {inputs.map((input, index) => (
                      <FormField
                        key={index}
                        {...{
                          input,
                          values,
                          handleBlur,
                          handleChange,
                          touched,
                          errors,
                        }}
                      />
                    ))}
                    <FormLabel
                      style={{ marginTop: "40px", marginLeft: "80px" }}
                    >
                      Contract Number
                    </FormLabel>
                    {inputs_date.map((input, index) => (
                      <FormField
                        key={index}
                        {...{
                          input,
                          values,
                          handleBlur,
                          handleChange,
                          touched,
                          errors,
                        }}
                      />
                    ))}
                  </Grid>
                </StyledFormElementsWrapper>
                <StyledFormElementsWrapper
                  style={{ marginBottom: 8, marginTop: 15 }}
                >
                  <ContainerGrid className="mis-dashboard">
                    {upload_templates.map((upload, index) => (
                      <div style={{ marginTop: 10 }}>
                        <FormLabel>
                          {upload.label}{" "}
                          <span className="required-star"> *</span>
                        </FormLabel>
                        <CustomFileUpload
                          error={touched[upload.fileName] && errors[upload.fileName]}
                          setFile={(file) =>
                            s3Upload(
                              file,
                              upload.name,
                              setFieldValue,
                              upload.types,
                              upload.accept,
                              values,
                              upload.fileName,
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                          accept={upload.accept}
                          file={{ name: values[upload.fileName] }}
                        />
                      </div>
                    ))}
                  </ContainerGrid>
                </StyledFormElementsWrapper>
                <StyledFormElementsWrapper
                  style={{
                    float: "left",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "25px",
                    width: "35%",
                  }}
                >
                  <Button
                    style={{
                      marginLeft: "unset",
                    }}
                  >
                    <PrimaryOutlinedCTAButton
                      onClick={() => navigate("/dashboard")}
                    >
                      Back
                    </PrimaryOutlinedCTAButton>
                  </Button>
                  <Button
                    style={{
                      marginLeft: "22px",
                    }}
                  >
                    <PrimaryCTAButton onClick={handleSubmit}>
                      Submit
                    </PrimaryCTAButton>
                  </Button>
                </StyledFormElementsWrapper>
              </StyledForm>
            )}
          </Formik>
        </CreateContainer>
      </Container>
      {addressLabel && <AddressLabel {...{ addressLabel, setAddressLabel }} />}
    </>
  );
};

export default Rename;

const Container = styled.div`
  padding: 24px 43px;
  display: flex;
  flex-direction: column;
`;

const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-bottom: 12px;
  padding-left: 13px;
  padding-right: 11px;
  background-color: #eeeeee;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 46px;
    padding-right: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
`;

const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
  max-width: 70%;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .template {
    margin-top: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
  @media ${theme?.breakpoints?.sm_down} {
    & .template {
      margin-top: 15px;
    }
  }
`;

const StyledForm = styled.form`
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
  }
  & .heading {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    & .delete {
      height: 20px;
      width: 20px;
      margin-bottom:15px;
      cursor: pointer;
      @media ${theme?.breakpoints?.sm_up} {
        margin: 34px 0 21px 0;
      }
    }
`;

const FormLabel = styled.div`
  display: flex;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  margin-left: 10px;
`;

const ContainerGrid = styled.div`
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }
`;

const Grid = styled.div`
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
  }
`;

const Button = styled.div`
  width: 50%;
`;
